.contactus_wrapper {
    color: var(--text-secondary-color-v1);
}

.contactus_sectionone {
    padding: 2rem 15rem;
}

.contactus_sectionone h1 {
    font-size: var(--heading-one);
}

.contactus_sectiontwo {
    display: flex;
    justify-content: space-between;
    padding: 0rem 15rem 5rem 15rem;
}

.input-wrapper {
    position: relative;
    width: 500px;
    margin: 50px auto;
  }
  
  .input-box {
    font-size: 16px;
    padding: 10px 0;
    border: none;
    border-bottom: 2px solid #ccc;
    color: var(--text-secondary-color-v1);
    width: 100%;
    background-color: transparent;
    transition: border-color 0.3s ease-in-out;
  }
  
  .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--main-color);
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .input-box:focus {
    border-color: #08AEEA;
    outline: none;
  }
  
  .input-box:focus + .underline {
    transform: scaleX(1);
  }

  @media screen and (max-width: 1080px) {
    .contactus_sectionone {
        padding: 2rem 3rem;
    }

    .contactus_sectiontwo {
        padding: 0rem 3rem 5rem 3rem;
    }

    .input-wrapper {
        width: 400px;
        margin: 50px auto;
      }
  }

  @media screen and (max-width: 490px) {
    .contactus_sectionone {
        padding: 0rem 2rem;
    }

    .contactus_sectiontwo {
        padding: 0rem 2rem 5rem 2rem;
        flex-direction: column;
    }

    .input-wrapper {
        width: 100%;
        margin: 30px auto;
      }
  }
  