.button {
    display: inline-block;
    border-radius: 4px;
    background-color: var(--secondary-color);
    border: none;
    color: var(--main-color);
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    padding: 16px;
    width: 170px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
   }
   
   .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
   }
   
   .button span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
   }
   
   .button:hover span {
    padding-right: 15px;
   }
   
   .button:hover span:after {
    opacity: 1;
    right: 0;
   }