.webdesigndevelopment_sectionone {
    background-image: url(https://res.cloudinary.com/emacon-production/image/upload/v1689836956/Emacon%20Production/jefferson-santos-9SoCnyQmkzI-unsplash_xw7irl.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: var(--main-color);
}

.webdesigndevelopment_hero_wrapper {
    padding: 3rem 8rem;
    color: var(--text-main-color);
    width: 50%;
}

.webdesigndevelopment_hero_wrapper h1 {
    font-size: var(--heading-one);
}

.webdesigndevelopment_hero_wrapper p {
    font-size: 20px;
    padding-bottom: 1rem;    
}

.webdesigndevelopment_sectiontwo {
    padding: 3rem 8rem;
    color: var(--text-secondary-color-v1);
}

.webdesigndevelopment_sectiontwo h2{
    text-align: center;
    font-size: 25px;
}

.webdesigndevelopment_sectiontwo img {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    object-position: 70% 30%;
}

.webdesigndevelopment_missionwrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0rem 2rem;
}

.webdesigndevelopment_mission {
    background: var(--main-color);
    color: var(--text-main-color);
    width: 35rem;
    padding: 1.5rem 2rem;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    margin-top: -5rem;
}

.webdesigndevelopment_mission h4 {
    margin: 0;
}

.webdesigndevelopment_mission p {
    margin: 1rem 0rem 0rem 0rem;
}

.webdesigndevelopment_sectionthree {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    gap: 50px 30px;
    padding: 2rem 0rem;
}

@media screen and (max-width: 1080px) {
    .webdesigndevelopment_hero_wrapper {
        padding: 3rem 2rem;
        width: 70%;
    }

    .webdesigndevelopment_sectiontwo {
        padding: 3rem 2rem;
    }

    .webdesigndevelopment_mission {
        width: 50%;
    }

    .webdesigndevelopment_sectionthree {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 490px) {
    .webdesigndevelopment_mission {
        width: 75%;
    }

    .webdesigndevelopment_sectionthree {
        grid-template-columns: auto;
    }
}