.card {
    width: 22em;
    height: 30em;
    background: var(--main-color-hover);
    transition: 1s ease-in-out;
    clip-path: polygon(30px 0%, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0% 30px);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .card span {
    font-weight: bold;
    color: white;
    text-align: center;
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0rem 0rem 0rem;
    padding: 0rem 1rem;
  }
  
  .card .info {
    font-weight: 400;
    color: white;
    display: block;
    text-align: center;
    font-size: 15px;
    margin: 1em;
  }
  
  .card .img {
    width: 100%;
    height: 10rem;
    background: white;
    border-radius: 15px;
    object-fit: cover;
  }
  
  .card .share {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    gap: 1em;
  }
  
  .card a {
    color: white;
    transition: .4s ease-in-out;
  }
  
  .card a:hover {
    color: red;
  }

  .card_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 4.5rem; */
    margin-top: 0.5rem;
  }
  
  /* .card button {
    padding: 0.8em 1.7em;
    display: block;
    margin: auto;
    border-radius: 25px;
    border: none;
    font-weight: bold;
    background: #ffffff;
    color: rgb(0, 0, 0);
    transition: .4s ease-in-out;
  }
  
  .card button:hover {
    background: red;
    color: white;
    cursor: pointer;
  } */