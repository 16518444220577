.digitalmarketing_sectionone {
    display: flex;
    justify-content: space-between;
    padding: 3rem 8rem;
}

.digitalmarketing_sectionone h1 {
    font-size: var(--heading-one);
}

.digitalmarketing_sectionone_desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.digitalmarketing_sectiontwo {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
    justify-content: center;
    gap: 50px 30px;
    padding: 4rem 0rem;
}

.digitalmarketing_sectionthree {
    background: var(--main-color);
    color: var(--text-main-color);
    padding: 3rem 8rem;
}

.digitalmarketing_approach img {
    width: 45rem !important; 
    height: 25rem !important;
    object-fit: cover;
    border-radius: 5%;
}

.digitalmarketing_sectionthree h2 {
    font-size: var(--heading-two);
}

.digitalmarketing_approach {
    display: flex;
    justify-content: center;
    padding: 3rem 0rem;
}

.digitalmarketing_approach div {
    padding: 0rem 3rem;
}

.digitalmarketing_contactform {
    padding: 3rem 8rem;
    display: flex;
    justify-content: center;
}

.digitalcontactform_sectionone {
    text-align: end;
    padding: 1rem 3rem;
}

@media screen and (max-width: 1080px) {
    .digitalmarketing_sectionone {
        padding: 3rem 2rem;
        flex-direction: column;
    }
    .digitalmarketing_sectiontwo {
        grid-template-columns: auto auto;
    }
    .digitalmarketing_sectionthree {
        padding: 3rem 2rem;
    }
    .digitalmarketing_approach img {
        width: 15rem !important; 
        height: 25rem !important;
    }

    .digitalmarketing_contactform {
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 490px) {
    .digitalmarketing_sectiontwo {
        grid-template-columns: auto;
    }

    .digitalmarketing_approach {
        flex-direction: column;
    }
    .digitalmarketing_approach img {
        width: 100% !important; 
        height: 25rem !important;
    }
    .digitalmarketing_approach div {
        padding: 0rem 0rem;
    }
    .digitalmarketing_contactform {
        flex-direction: column;
    }

    .digitalcontactform_sectionone {
        text-align: start;
        padding: 0rem 0rem;
    }
}
