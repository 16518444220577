.projectCard_wrapper {
    width: 22em;
    height: fit-content;
    padding: 0rem 2rem 3rem 2rem;
    /* background: var(--secondary-color); */
}

.projectCard_thumbnail img{
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border-radius: 15px;
}

@media screen and (max-width: 1080px) {
    .projectCard_wrapper {
        width: 20em;
        height: fit-content;
        padding: 1rem 1rem;
        /* background: var(--secondary-color); */
    }
}

@media screen and (max-width: 490px) {
    
}