.home_hero_wrapper {
    /* display: flex;
    justify-content: space-between; */
    /* text-align: center; */
    display: grid;
    grid-template-columns: auto auto;
    /* align-items: center; */
    justify-content: space-between;
    gap: 50px 25px;
    padding: 1rem 8rem;
    color: var(--text-main-color);
    background: var(--main-color);
    height: 400px;
}

.home_hero_sectionone h1 {
    font-size: var(--heading-one);
}

.home_hero_sectiontwo {
    padding: 4rem 2rem;
    font-size: var(--text-one);
}

.home_sctiontwo_showreel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 28rem;
}

.home_sctiontwo_showreel iframe {
    width: 860px;
    height: 480px;
    border-radius: 15px 15px;
}

.home_sectiontwo_logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25rem;
    /* background-color: var(--main-color); */
}

.home_sectiontwo_logos img {
    width: 7.5rem;
    aspect-ratio: 3/2;
    object-fit: contain;
    filter: grayscale(100%) invert(40%);
    padding: 1rem 2rem;
    /* filter: invert(100%); */
    /* mix-blend-mode: color; */
}

.about_wrapper {
    display: flex;
    justify-content: center;
    padding: 4rem 8rem;
}

.about_wrapper img {
    height: 35rem;
    width: 35rem;
    object-fit: cover;
    border-radius: 15px 15px;
}

.about_content {
    padding: 0rem 2rem;
    color: var(--main-color);
}

.about_content h3 {
    font-weight: 700;
}

.about_content h2 {
    font-size: var(--heading-two);
    font-weight: 700;
}

.home_services_wrapper {
    padding: 2rem 8rem;
    background: var(--main-color);
}

.home_services_sectionOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-main-color);
}

.home_services_sectionOne h1 {
    font-size: 3rem;
}

.home_services_sectionOne p {
    font-size: var(--text-one);
    width: 50%;
}

.home_services_sectionTwo {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
    justify-content: center;
    gap: 50px 25px;
    padding: 3rem 0rem;
}

.how_we_work_wrapper {
    padding: 3rem 8rem;
}

.how_we_work_sectionOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-secondary-color-v1);
}

.how_we_work_sectionOne h1 {
    font-size: 3rem;
}

.how_we_work_sectionOne p {
    font-size: var(--text-one);
    width: 50%;
}

.how_we_work_sectionTwo {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
    justify-content: center;
    gap: 50px 20px;
}

.stage_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-secondary-color-v1);
}

.stage_icon_wrapper {
    background: #445457;
    border-radius: 50%;
    padding: 1rem;
}

.stage_icon_wrapper i{
    font-size: 2.5rem;
    color: var(--text-main-color);
}

.stage_wrapper p{
    text-align: center;
}
.how_we_work_wrapper {
    padding: 3rem 3rem;
}

.works_wrapper {
    padding: 2rem 8rem;
    color: var(--text-secondary-color-v1);
}

.works_wrapper h1 {
    text-align: center;
    font-size: 3rem;
}

.works_Menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.works_Menu p {
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    transition: all .2s;
}

.works_Menu p:hover {
    color: var(--text-secondary-color-v2);
}

.works_Menu .activeWorksMenu {
    color: var(--text-secondary-color-v2);
    font-weight: 700;
    border-bottom: 2px solid var(--text-secondary-color-v2);
}

.works_data {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.works_learnmore {
    display: flex;
    justify-content: center;
    padding: 2rem 0rem;
}

.testimonials_wrapper {
    padding: 3rem 8rem;
    background: var(--main-color);
}

.testimonials_sectionOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-main-color);
}

.testimonials_sectionOne h1 {
    font-size: 3rem;
}

.testimonials_sectionTwo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1.5rem 0rem 0rem 0rem;
}

.insights_wrapper {
    padding: 3rem 7.5rem;
}

.insights_sectionOne h1 {
    color: var(--text-secondary-color-v1);
    font-size: 3rem;
}

.insights_data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 1080px) {
    .home_hero_wrapper {
        grid-template-columns: auto auto auto;
        padding: 1rem 3rem;
        color: var(--text-main-color);
    }
    .home_hero_sectionone h1 {
        font-size: 50px;
    }

    .home_hero_sectiontwo {
        padding: 1rem 0rem 1rem 1rem;
    }

    .home_sctiontwo_showreel iframe {
        width: 660px;
        height: 380px;
    }

    .home_sectiontwo_logos {
        margin-top: 16rem;
    }
    .home_sectiontwo_logos img {
        width: 6rem;
        padding: 1rem 1rem;
    }
    .about_wrapper {
        flex-direction: column;
        padding: 4rem 3rem;
    }
    .about_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .about_wrapper img {
        height: 25rem;
        width: 35rem;
    }
    .home_services_wrapper {
        padding: 2rem 3rem;
    }
    .home_services_sectionTwo {
        grid-template-columns: auto auto;
        gap: 50px 25px;
    }
    .works_wrapper {
        padding: 2rem 3rem;
    }
    .testimonials_wrapper {
        padding: 3rem 3rem;
    }
    .insights_wrapper {
        padding: 3rem 3rem;
    }
    
}

@media screen and (max-width: 490px) {
    .home_hero_wrapper {
        grid-template-columns: auto;
        gap: 1px 25px;
        padding: 1rem 2rem;
        color: var(--text-main-color);
        height: 600px;
    }
    .home_hero_sectiontwo {
        padding: 0rem 0rem 0rem 0rem;
        font-size: var(--text-one);
    }
    .home_sctiontwo_showreel {
        top: 45rem;
    }
    .home_sctiontwo_showreel iframe {
        width: 360px;
        height: 210px;
    }
    .home_sectiontwo_logos {
        margin-top: 10rem;
    }
    .home_sectiontwo_logos img {
        width: 5rem;
        padding: 1rem 1rem;
    }
    .about_wrapper {
        padding: 4rem 2rem;
    }
    .about_wrapper img {
        height: 12rem;
        width: 18rem;
    }
    .about_content {
        padding: 0rem 0rem;
    }
    .home_services_wrapper {
        padding: 2rem 2rem;
    }
    .home_services_sectionOne {
        align-items: flex-start;
        flex-direction: column;
    }
    .home_services_sectionOne p {
        width: 100%;
    }
    .home_services_sectionTwo {
        grid-template-columns: auto;
        gap: 50px 25px;
    }
    .how_we_work_wrapper {
        padding: 3rem 2rem;
    }
    
    .how_we_work_sectionOne {
        align-items: flex-start;
        flex-direction: column;
    }
    .how_we_work_sectionOne p {
        width: 100%;
    }
    .how_we_work_sectionTwo {
        grid-template-columns: auto;
    }
    .works_Menu p {
        padding: 0.5rem 0.5rem;
    }
    .works_wrapper {
        padding: 2rem 2rem;
    }
    .testimonials_wrapper {
        padding: 3rem 2rem;
    }
    .insights_wrapper {
        padding: 3rem 2rem;
    }
}