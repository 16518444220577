.aboutpage_sectionone{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* flex-wrap: wrap; */
    padding: 2rem 8rem;
    color: var(--text-secondary-color-v1);
}

.aboutpage_sectionone h1 {
    font-size: var(--heading-one);
}

.aboutpage_sectionone p {
    font-size: var(--text-one);
    padding: 0rem 0rem 0rem 2rem;
}

.aboutpage_sectiontwo {
    padding: 2rem 8rem;
    color: var(--text-secondary-color-v1);
    text-align: center;
}

.aboutpage_sectiontwo h1 {
    font-size: var(--heading-one);
}

.aboutpage_sectiontwo p {
    font-size: var(--text-one);
}

.teamcards_wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    gap: 50px 30px;
    padding: 1rem 0rem;
    margin-top: 2rem;
}

.teamcard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.teamcard img {
    height: 17rem;
    width: 17rem;
    object-fit: cover;
}

.teamcard_details {
    background: var(--main-color);
    color: var(--text-main-color);
    width: 13rem;
    height: fit-content;
    padding: 1rem 0.5rem;
    margin-top: -2.5rem;
}

.teamcard h4 {
    margin: 0;
}

.teamcard_details p {
    font-size: 12px;
    margin: 0;
    margin-top: 0.5rem;
}

@media screen and (max-width: 1080px) {
    .aboutpage_sectionone{
        padding: 2rem 3rem;
    }
    .aboutpage_sectionone p {
        padding: 0rem 0rem 0rem 1rem;
    }

    .aboutpage_sectiontwo {
        padding: 2rem 2rem;
    }

    .teamcards_wrapper {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 490px) {
    .aboutpage_sectionone{
        padding: 1rem 2rem;
        flex-direction: column;
    }
    .aboutpage_sectionone p {
        font-size: var(--text-one);
        padding: 0rem 0rem 0rem 0rem;
    }

    .teamcards_wrapper {
        grid-template-columns: auto;
    }
}