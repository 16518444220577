.photographyvideo_hero {
    background: var(--main-color);
    z-index: -1 !important;
}
.hero_video {
    /* position: absolute; */
    /* right: 0;
    bottom: 0; */
    height: 40rem !important;
    width: 100%;
    object-fit: cover;
    /* z-index: -2 !important; */
    opacity: 0.3;
}

.photographyvideo_hero_content {
    position: absolute;
    width: 60%;
    height: 40rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem 8rem;
    margin-top: 6rem;
    color: var(--text-main-color);
    /* z-index: -1 !important; */
}

.photographyvideo_hero_content h1 {
    font-size: var(--heading-one);
}

.photographyvideo_hero_content p {
    font-size: 20px;
    padding-bottom: 2rem;    
}

.projecttypes_wrapper {
    padding: 3rem 8rem;
}

.projecttypes_wrapper h1{
    font-size: var(--heading-two);
    text-align: center;
}


.projectTypes h3 {
    font-weight: 800;
    text-align: center;
}

.projectTypes p {
    text-align: center;
}

.projectTypes {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    gap: 50px 30px;
    padding: 1rem 0rem;
}

@media screen and (max-width: 1080px) {
    .photographyvideo_hero_content {
        width: 80%;
        height: 40rem;
        padding: 3rem 2rem;
    }

    .projecttypes_wrapper {
        padding: 3rem 2rem;
    }

    
}
@media screen and (max-width: 490px) {
   
    .photographyvideo_hero_content h1 {
        font-size: 35px;
    }

    .photographyvideo_hero_content p {
        font-size: 15px;
        padding-bottom: 1rem;    
    }

    .hero_video {
        height: 30rem !important;
    }

    .photographyvideo_hero_content {
        width: 70%;
        height: 30rem;
        padding: 3rem 2rem;
    }

    .projectTypes {
        grid-template-columns: auto;
    }
}


/* @media (min-aspect-ratio: 16/9){
    .hero_video {
        width: 100%;
        height: auto;
    }
}

@media (max-aspect-ratio: 16/9){
    .hero_video {
        width: auto;
        height: 100%;
    }
} */